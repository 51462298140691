/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: "Playfair Display", serif;
  background-color: white;
  .bg-header {
    background-color: black;
    overflow-x: none;
  }
}
.selected-day {
  background-color: lightblue; /* Couleur de fond pour la date sélectionnée */
}
.weekend {
  color: red;
}
